@font-face {
  font-family: Invention;
  src: url(./assets/fonts/Invention_Rg.ttf);
  font-weight: normal;
}

@font-face {
  font-family: Invention;
  src: url(./assets/fonts/Invention_Bd.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Invention;
  src: url(./assets/fonts/Invention_It.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Invention;
  src: url(./assets/fonts/Invention_BdIt.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Invention;
  src: url(./assets/fonts/Invention_Lt.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Invention;
  src: url(./assets/fonts/Invention_LtIt.ttf);
  font-weight: 200;
  font-style: italic;
}

:root {
  --teal-color: 0, 133, 124;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  position: relative;
  padding-bottom: 250px;
}

body.overflow-hidden {
  overflow: hidden;
}

* {
  font-family: Invention;
  color: rgb(63, 59, 60);
  /* padding: 0;
  margin: 0; */
}

.navbar.bg-light {
  margin-bottom: 20px !important;
  background: white !important;
}

h2.ghi {
  font-size: 28px;
}
.bold {
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

.white-text {
  color: white;
  font-weight: normal;
}

.navbar-brand {
  margin-bottom: -7px !important;
}

.nav-link {
  color: rgb(var(--teal-color)) !important;
}

button.navbar-toggler {
  border: none !important;
  z-index: 10;
}

.portfolio-carousel {
  width: 90% !important;
  margin: auto !important;
  box-shadow: rgba(103, 103, 103, 0.3) 0 0 15px !important;
}

.portfolio-carousel .carousel-inner .carousel-item {
  padding: 30px;
}

.portfolio-carousel .carousel-indicators {
  display: none;
}

.portfolio-carousel .carousel-control-prev .carousel-control-prev-icon {
  background-image: url('./assets/images/icons/left-arrow.svg');
}

.portfolio-carousel .carousel-control-next .carousel-control-next-icon {
  background-image: url('./assets/images/icons/right-arrow.svg');
}

span.geotargetlygeopopup1712182168396close, span.geotargetlygeopopup1712181814719close {
    display: none !important;
}

@media only screen and (max-width: 992px) {
  .nav-link {
    text-align: right;
  }

  .navbar-nav {
    align-items: end;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: white;
    padding-right: 10px;
    z-index: 1;
  }
}

@media only screen and (max-width: 715px) {
  body {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 442px) {
  .navbar-brand {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 400px) {
  .portfolio-carousel {
    width: 95% !important;
  }

  .portfolio-carousel .carousel-inner .carousel-item {
    padding: 10 20px;
  }
}
